import React, { useState } from "react";

import Alert from "@mui/material/Alert";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';

import { GetApiKeyValue } from "../../../adl-gen/whistle/xsync/api";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Popover, Typography } from "@mui/material";

// https://mui.com/x/react-date-pickers/getting-started/
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import format from "date-fns/format";

interface Column<T> {
  header: string;
  renderCell: (t: T) => JSX.Element;
}

type DownloadType = {
  name: string;
  url: string;
  comment: string;
  download: string;

  atDate: Date|null;
  setAtDate: ((d: Date)=>void)|null;

  //dateRange?: [Date,Date];
  //setDateRange?: (d: [Date,Date])=>void;
};

function DownloadUrl(props: {content: string}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any)=>{
    const el = event.currentTarget;
    navigator.clipboard.writeText(props.content).then(()=>{
      setAnchorEl(el);
    }).catch(err=>{});
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <Stack direction="row" spacing={1}>
    <ContentCopyIcon onClick={(event)=>{
      handleClick(event);

      setTimeout(handleClose, 2000);
    }}/>
    <Popover
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: -10,
      }} 
      open={open}>
      <Typography sx={{ p: 1 }}>Copied.</Typography>
    </Popover>
  </Stack>;
}

const cols : Column<DownloadType>[] = [
  {
    header: "Name",
    renderCell: (val)=><div>{val.name}</div>
  },
  {
    header: "Comment",
    renderCell: (val)=><div>{val.comment}</div>
  },
  {
    header: "Date",
    renderCell: (val)=>{

      if(!val.atDate || !val.setAtDate) {
        return <div></div>
      }

      const value = val.atDate;
      const setValue = val.setAtDate;

      return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="At Date"
          value={value}
          onChange={setValue}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    }
  },
  {
    header: "Download",
    renderCell: (val)=><Link href={val.url} download={val.download}><DownloadIcon/></Link>
  },
  {
    header: "Copy URL",
    renderCell: val=><DownloadUrl content={window.location.origin + val.url}/>
  }
]

function DownloadRow(props: {row: DownloadType}) {
  const {row} = props;
  return <TableRow>{
    cols.map(c=><TableCell key={c.header}>
      {c.renderCell(row)}
    </TableCell>)
  }</TableRow>;
}

function DownloadsTable(props: {rows: DownloadType[], apiKey: GetApiKeyValue}) {
  const {rows, apiKey} = props; 
  return <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="downloads table">
      <TableHead>
        <TableRow>
          {cols.map(c=><TableCell key={c.header}>
            {c.header}
          </TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(r=>
          <DownloadRow row={r} key={r.name}/>
        )}

        <BalanceSheetDownload apiKey={apiKey}/>

      </TableBody>
    </Table>
  </TableContainer>;
}

// http://localhost:3000/_a/csv/balanceSheet/2022-10-19?auth=eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyLWlkIjoiVXg0dFdCb0hzUFZEIiwicm9sZXMiOlsiYW55b25lIiwiZGF0YXJlYWQiXSwibmFtZSI6ImRlZmF1bHQifQ.lHwWuWcueDmqgZqvA-mXX0WTv0nYKIQwcPytx212tfk

function BalanceSheetDownload(props: {apiKey: GetApiKeyValue}){
  const {apiKey} = props;
  const [atDate, setAtDate] = useState<Date>(new Date());

  const atDateFmt = format(atDate,"yyyy-MM-dd");
  const url = `/_a/csv/balanceSheet/${atDateFmt}?auth=${apiKey.apiKeyJWT}`;
  const download = `balance_sheet_${atDateFmt}.csv`;

  const row : DownloadType = {
    name: "Balance Sheet",
    comment: "Accounts for Balance Sheet at date",
    url,
    download,

    atDate,
    setAtDate
  };

  return <DownloadRow row={row} key={row.name}/>;
}

export function DownloadCsvsComponent(props: { rows: GetApiKeyValue[], showAggregatedJournal: boolean }) {

  const [value, setValue] = useState<Date|null>(new Date());

  const { rows, showAggregatedJournal } = props;
  
  if (rows.length === 0) {
    return <Alert severity="error">No API keys available</Alert>;
  }

  const apiKey = rows[0];

  const journalsUrl = `/_a/csv/journals?auth=${apiKey.apiKeyJWT}`;
  const journalsAggUrl = `/_a/csv/journals/agg?auth=${apiKey.apiKeyJWT}`;

  const downloads : DownloadType[] = [
    {
      name: "Journals",
      comment: "Journal lines with journal, organisation and account headers",
      url: journalsUrl,
      download: "journals.csv",

      atDate: null, setAtDate: null
    }
  ];

  if (showAggregatedJournal) {
    downloads.push({
      name: "Journals Aggregated",
      comment: "Journals aggregated by month & account",
      url: journalsAggUrl,
      download: "journals_agg.csv",

      atDate: null, setAtDate: null
    });
  }

  return <Stack>
    <h4>Download</h4>
    <DownloadsTable apiKey={apiKey} rows={downloads}/>
  </Stack>;
}
