import React from "react";
import { UserLayout } from "../layout/UserLayout";

import Container from "@mui/material/Container";

import { useLoggedInUserContext } from "../../hooks/loggedInContext";
import { AppService } from "../../adl-gen/app-service";
import { DownloadCsvsComponent } from "../components/export/DownloadCsvsComponent";
import { GetApiKeyValue } from "../../adl-gen/whistle/xsync/api";
import { CircularProgress } from "@mui/material";

export function ExportDataPage() {
  const loggedInUser = useLoggedInUserContext();
  const service: AppService = loggedInUser.apis.app;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState<GetApiKeyValue[]>([]);
  const [showAggregatedJournal, setShowAggregatedJournal] = React.useState<boolean>(false);
  
  const loadApiKeys = async () => {
    setLoading(true);
    const apiKeys = await service.getApiKeys({});
    setRows(apiKeys);
    const showJournal = await service.getDisplayAggJournalLink();
    setShowAggregatedJournal(showJournal || loggedInUser.profile.isAdmin ? true : false);
    setLoading(false);
  };

  React.useEffect(() => {
    void loadApiKeys();
  }, []);

  return (
    <UserLayout>
      <Container>

        {loading ? <CircularProgress/> : 
          <DownloadCsvsComponent rows={rows} showAggregatedJournal={showAggregatedJournal}/>
        }
        
      </Container>
    </UserLayout>
  );
}
